

a,
body.mce-content-body a{
	transition: color 0.4s, border 0.3s;
	text-decoration: none;
	color: $colorDark100;
	font-weight: bold;

	&:hover{
		text-decoration: none;
		color: $colorMain100;
	}
}


article p,
section p,
body.mce-content-body p{
	padding-bottom: 16px;
}


strong, b,
body.mce-content-body strong,
body.mce-content-body b{
	font-weight: 700;
}


h1,h2,h3,h4,
body.mce-content-body h1,
body.mce-content-body h2,
body.mce-content-body h3,
body.mce-content-body h4{
	font-weight: 200;
	line-height: 1.5;
	margin-bottom: 1.5em;
}

h1,
body.mce-content-body h1{
	font-size: var(--font-xl);
}


h2,
body.mce-content-body h2{
	font-size: var(--font-l);
}
