
@import "helpers/hl--global-placeholders";
@import "helpers/hl--mixins";
@import "helpers/hl--reset";
@import "helpers/hl--variables";

html{
	height: 100%;
	min-height: 100%;

	font-size: 14px;
	line-height: 1.5;

	@include mqUp(1024px){
		font-size: 16px;
	}
	@include mqUp(1500px){
		font-size: 18px;
	}


}


body{
	height: 100%;
	min-height: 100%;
	background-color: $colorLight;
	font-family: $fontMain;
	color: $colorDark100;

}

.content-wrap{
	display: flex;
	flex-flow:row wrap;
	width: 100%;
	min-height: 100%;
	padding: var(--space-l);
	background-image: linear-gradient(rgba(#0072DD, 1.0) 35%, rgba(#00CFDD, 1.0) 100%);
}


.intro-content{
	color: $colorLight;
	flex-basis: 42%;
	flex-shrink: 0;
	padding: 20px;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: auto 60%;
	background-image: url(../gfx/illustration.svg);

	h1{
		padding-top: var(--space-l);
	}

	@include mq(1000px){
		background-position: right center;
		background-size: auto 90%;
		padding-right: 40%;
	}

	@include mq(1000px){
		flex-basis: 100%;
	}

	@include mq(640px){
		background-image: none;
		padding-right: 0;

		h1{
			margin-bottom: 0;
		}
	}


}

.main-content{
	flex-basis: 58%;
	background-color: $colorLight;
	display: flex;
	padding: var(--space-xxl);
	border-radius: 15px;

	@include mq(1000px){
		flex-basis: 100%;
	}


	.main-content-centered{
		margin: auto;
	}
}


.contact-details{
	margin-top: var(--space-m);
	padding-left: 70px;
	padding-top: 10px;
	padding-bottom: 10px;
	background-repeat: no-repeat;
	background-position: left center;


	&--mail{
		background-size: 50px auto;
		background-image: url(../gfx/icon-mail.svg);
	}

	&--phone{
		background-size: 48px auto;
		background-image: url(../gfx/icon-mobile.svg);
	}

	@include mq(540px){
		padding-left: 0;
		padding-top: 54px;
		background-position: center top;
		text-align: center;

	}


}

@import "general_styles/gen--typography";
