//short code for media querie
@mixin mq($value) {
	@media only screen and (max-width: $value) {
		@content;
	}
}

@mixin mqH($value) {
	@media only screen and (max-height: $value) {
		@content;
	}
}

@mixin mqUp($value) {
	@media only screen and (min-width: $value) {
		@content;
	}
}

@mixin ie() {
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		@content;
	}
}
