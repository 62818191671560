
/* declare spacing elements and type sizes as css variables and change the sizes according to screen size, starting from mobiles and moving up*/
:root{

	// spacing elements
	--space-min: 4px;
	--space-xs: 8px;
	--space-s: 12px;
	--space-m: 16px;
	--space-l: 24px;
	--space-xl: 36px;
	--space-xxl: 48px;
	--space-max: 64px;

	// Large (heading) font sizes
	--font-xs: 14px;
	--font-s: 18px;
	--font-m: 22px;
	--font-l: 26px;
	--font-xl: 30px;
	--font-xxl: 34px;

	--side-space: 16px;
}


@include mqUp(2000px){
	:root{
		// spacing elements
		--space-min: 16px;
		--space-xs: 24px;
		--space-s: 32px;
		--space-m: 48px;
		--space-l: 64px;
		--space-xl: 96px;
		--space-xxl: 128px;
		--space-max: 196px;

		// Large (heading) font sizes
		--font-xs: 18px;
		--font-s: 26px;
		--font-m: 32px;
		--font-l: 40px;
		--font-xl: 54px;
		--font-xxl: 64px;

		--side-space: 48px;
	}

}

@include mqUp(1300px){
	:root{
		// spacing elements
		--space-min: 8px;
		--space-xs: 16px;
		--space-s: 24px;
		--space-m: 32px;
		--space-l: 48px;
		--space-xl: 64px;
		--space-xxl: 96px;
		--space-max: 128px;

		// Large (heading) font sizes
		--font-xs: 16px;
		--font-s: 20px;
		--font-m: 26px;
		--font-l: 30px;
		--font-xl: 40px;
		--font-xxl: 50px;

		--side-space: 36px;
	}

}


// adjust spacing elements and type sizes for desktop
@include mqUp(760px){

	:root{

		// spacing elements
		--space-min: 8px;
		--space-xs: 12px;
		--space-s: 16px;
		--space-m: 24px;
		--space-l: 32px;
		--space-xl: 48px;
		--space-xxl: 64px;
		--space-max: 72px;

		// Large (heading) font sizes
		--font-xs: 16px;
		--font-s: 20px;
		--font-m: 26px;
		--font-l: 32px;
		--font-xl: 36px;
		--font-xxl: 44px;

		--side-space: 24px;
	}
}






$fontMain: 'Open sans', sans-serif;
$fontDisplay: 'Open sans',  sans-serif;




$colorDark100: #033C39; // black

// Dark color different shades
$colorDark90: #0D1F2D;
$colorDark80: #2E404E;
$colorDark70: #405260;
$colorDark60: #5A6C7A; // Lightest dark color for minimum contrast on white => 16px
$colorDark50: #7190A7; // Lightest dark color for minimum contrast on white =< 18px
$colorDark40: #C0CED9;
$colorDark30: #DDE6ED;
$colorDark20: #ECF2F7;
$colorDark10: #F5FAFC; // light gray - almost white

//Lightest (white) color
$colorLight: #ffffff;


//Main brand color alternative shades:
$colorMain90: #109B78; //lighter shade for main brand color
$colorMain100: #0072DD; //Main brand color
$colorMain110: #05544F; //darker shade 1 for main brand color


//Accent colors
$colorAccent90: #FFF45E;
$colorAccent100: #FFF23D;
$colorAccent110: #F4DC00;


// Colors for success/error messages
$colorSuccess: #00C041; // Green color for success messages
$colorError: #EC0000; // Red color for error messages
$colorWarning: #FFFDC7;
